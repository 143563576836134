import { Controller } from 'stimulus';
import { setCookieForever } from 'core/utils/cookies';

export default class extends Controller {
  hide() {
    this.element.classList.add('hidden');
    const cookieName = `starting_points_hidden_for_account_${this.element.getAttribute('data-account-id')}`;

    setCookieForever(cookieName, true);
  }
}
