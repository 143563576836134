import { Controller } from 'stimulus';
// import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';
import {Datasource} from 'core/grid/datasource'
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';

const columnOptions = {
  columnDefs: {
    filename: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    invoice_date: { cellClass: 'font-mono', maxWidth: 300, sortable: false },
    invoice_amount: {
      cellClass: 'ag-right-aligned-cell font-mono', maxWidth: 200, sortable: false,
      headerClass: 'ag-header-cell ag-header-cell-sortable text-xs font-medium text-gray-500 uppercase bg-gray-100'
                   + ' ag-focus-managed ag-right-aligned-header' },
    currency_code: { cellClass: 'font-mono', maxWidth: 100, sortable: false },
    actions: { cellClass: 'ag-right-aligned-cell font-mono', maxWidth: 120, sortable: false, withoutHeader: true },
  },
  cookiesName: 'invoices_columns_positions',
  defaultSorting: [
    'filename',
    'invoice_date',
    'invoice_amount',
    'currency_code',
    'actions'
  ],
};

function buildGrid(gridElement, callbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new Datasource({ limit: paginationPageSize, callbacks }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#invoicesGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {})
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
