import { Controller } from 'stimulus';
import { uncheck } from 'core/utils/helpers';

export default class extends Controller {
  applyFilters(event) {
    event.target.closest('form').submit()
  }

  clearOneFilter(event) {
    uncheck(event.target.closest('.accordion-element').querySelectorAll('input[type="checkbox"]'))
  }

  clearFilters(event) {
    uncheck(event.target.closest('form'))
    this.applyFilters(event);
  }
}
