export function arrayEquals(arr1, arr2) {
  const a = [...arr1].sort();
  const b = [...arr2].sort();
  return (
    Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => val === b[index])
  );
}

export function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}
