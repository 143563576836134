import { Controller } from 'stimulus';

function enableElements(elements, value) {
  elements.forEach((el) => {
    el.disabled = !value;
  });
}

function addToggleListener(toggleEl, wrapperEl, taxIdWrapEl) {
  if (!toggleEl) return;

  function toggle() {
    const fieldEls = wrapperEl.querySelectorAll('input, select');

    enableElements(fieldEls, toggleEl.checked);
    wrapperEl.style.display = toggleEl.checked ? 'block' : 'none';

    if (toggleEl.checked) {
      const exemptSelect = document.querySelector("#customer_tax_settings_exempt");
      if (exemptSelect) {
        toggleTaxId({ target: exemptSelect });
      }
    } else {
      taxIdWrapEl.style.display = 'none';
      enableElements(taxIdWrapEl.querySelectorAll('input'), false);
    }
  }

  toggleEl.addEventListener('change', toggle);

  if (toggleEl.toggleListener) {
    toggleEl.removeEventListener('change', toggleEl.toggleListener);
  }

  toggleEl.toggleListener = toggle;
}

function toggleTaxId(event) {
  const exemptSelect = event.target;
  const taxIdWrapEl = document.querySelector("#customer_tax_settings_tax_id_wrap");
  const taxIdInput = document.querySelector("#customer_tax_settings_tax_id");
  const shouldShowTaxId = exemptSelect.value === "REVERSE" || exemptSelect.value === "EXEMPT";

  if (taxIdInput) {
    taxIdInput.disabled = !shouldShowTaxId;
    taxIdInput.required = shouldShowTaxId;
  }

  if (taxIdWrapEl) {
    taxIdWrapEl.style.display = shouldShowTaxId ? "block" : "none";
    enableElements(taxIdWrapEl.querySelectorAll('input'), shouldShowTaxId);
  }
}

export default class extends Controller {
  connect() {
    const exemptSelect = this.element.querySelector("#customer_tax_settings_exempt");
    const taxIdWrapEl = document.querySelector("#customer_tax_settings_tax_id_wrap");
    const toggleEl = this.element.querySelector("input[name=toggle-customer-tax-settings]");
    addToggleListener(toggleEl, this.element.querySelector("#customer-tax-settings-wrap"), taxIdWrapEl);

    if (exemptSelect) {
      exemptSelect.addEventListener("change", toggleTaxId);
      toggleTaxId({ target: exemptSelect });
    }

    addToggleListener(
      this.element.querySelector("input[name=toggle-customer-address]"),
      this.element.querySelector("#customer-address-wrap")
    );
  }
}
