import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  deleteToken(event) {
    const data = event.currentTarget.dataset;
    if (confirm(data.confirmText)) {
      Rails.ajax({
        type: 'DELETE',
        url: `/signup_codes/${data.id}`,
        success: (_data, _status, _xhr) => {
          this.refreshPage();
        },
        error: (data) => {
          this.refreshPage();
        },
      });
    }
  }

  refreshPage() {
    document.location.reload();
  }
}
