export class LinkCellRenderer {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = (params.value !== null && params.value !== undefined) ? params.value : '';

    const links = this.eGui.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', (e) => {
        e.stopPropagation(); // Prevent triggering the grid's onCellClicked
        Rails.visit(link.getAttribute('href'));
      });
    });
  }

  getGui() {
    return this.eGui;
  }
}
