import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'hideIcon', 'showIcon']

  hideValue() {
    this.fieldTarget.type = 'password'
    this.hideIconTarget.classList.add('hidden')
    this.showIconTarget.classList.remove('hidden')
  }

  showValue() {
    this.fieldTarget.type = 'text'
    this.hideIconTarget.classList.remove('hidden')
    this.showIconTarget.classList.add('hidden')
  }
}
