import { Controller } from 'stimulus';
import { uuidv4 } from 'core/utils/uuid';
import { isEscPressed } from 'core/utils/shortcuts';

export default class extends Controller {
  connect() {
    if (!this.element.hasAttribute('id')) {
      this.element.setAttribute('id', `dropdown${uuidv4()}`);
    }
    this.update();
  }

  update() {
    const buttons = document.querySelectorAll(`#${this.element.id} > button`);
    const menus = document.querySelectorAll(
      `#${this.element.id} .dropdown-menu`,
    );

    buttons.forEach((button, i) => {
      if (!button.id) {
        button.setAttribute('id', `${this.element.id}Button${i}`);
        menus[i].setAttribute(
          'aria-labelledby',
          `${this.element.id}Button${i}`,
        );
      } else {
        menus[i].setAttribute('aria-labelledby', button.id);
      }
      button.setAttribute('aria-controls', `${this.element.id}Menu${i}`);
      button.setAttribute('aria-haspopup', 'true');
      button.setAttribute('aria-expanded', 'false');
      menus[i].setAttribute('id', `${this.element.id}Menu${i}`);
      menus[i].setAttribute('aria-labelledby', `${this.element.id}Button${i}`);

      button.addEventListener('click', () => {
        this.toggleHandler(button, menus[i]);
      });
    });
  }

  toggleHandler(button, menu) {
    if (menu.classList.contains('hidden')) {
      this.open(button, menu);
    }
  }

  open(button, menu) {
    menu.classList.add('block');
    menu.classList.remove('hidden');
    button.setAttribute('aria-expanded', 'true');
    button.classList.add('ring-2', 'ring-offset-2', 'ring-primary-500');
    menu.focus();
    this.hideOnClickOutside(menu, button, menu);
  }

  close(button, menu) {
    menu.classList.add('hidden');
    menu.classList.remove('block');
    button.classList.remove('ring-2', 'ring-offset-2', 'ring-primary-500');
    button.setAttribute('aria-expanded', 'false');
  }

  hideOnClickOutside(element, button, menu) {
    let firstClick = true;
    const outsideClickListener = (event) => {
      if (
        !element.contains(event.target)
        && this.__isVisible(element)
        && !firstClick
      ) {
        this.close(button, menu);
        removeListeners();
      }
      firstClick = false;
    };
    const escKeydownListener = (event) => {
      if (this.__isVisible(element) && isEscPressed(event)) {
        this.close(button, menu);
        removeListeners();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
      firstClick = true;
    };
    const removeEscKeydownListener = () => {
      document.removeEventListener('keydown', escKeydownListener);
    };
    const removeListeners = () => {
      removeClickListener();
      removeEscKeydownListener();
    };

    document.addEventListener('click', outsideClickListener);
    document.addEventListener('keydown', escKeydownListener);
  }

  __isVisible(elem) {
    return !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
  }
}
