import { Controller } from 'stimulus';
import { uuidv4 } from 'core/utils/uuid';
import { enableShortcuts, disableShortcuts, isEscPressed, isTabPressed } from 'core/utils/shortcuts';

export default class extends Controller {
  connect() {
    if (!this.element.hasAttribute('id')) {
      this.element.setAttribute('id', `modal${uuidv4()}`);
    }
    this.update();
  }

  update() {
    const buttons = document.querySelectorAll(
      `[data-modal-target="${this.element.id}"]`,
    );
    const modal = document.querySelector(`#${this.element.id}`);
    const wrapper = modal.closest('.modal-wrapper');
    const closeButtons = modal.querySelectorAll('.modal-close');
    const context = this;

    modal.addEventListener('keydown', function(e) {
      if (isEscPressed(e)) {
        context.close(null, modal, wrapper);
        return;
      }

      if (!isTabPressed(e)) return;

      const focusableElements = modal.querySelectorAll(
        'button:not([style="display: none;"]), input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });

    buttons.forEach((button, i) => {
      if (!button.id) {
        button.setAttribute('id', `${this.element.id}Button${i}`);
        modal.setAttribute('aria-labelledby', `${this.element.id}Button${i}`);
      } else {
        modal.setAttribute('aria-labelledby', button.id);
      }
      button.setAttribute('aria-controls', `${this.element.id}Menu${i}`);

      button.addEventListener('click', () => {
        this.open(button, modal, wrapper);
      });
    });

    closeButtons.forEach((button, i) => {
      button.setAttribute('aria-controls', `${this.element.id}Menu${i}`);

      button.addEventListener('click', () => {
        this.close(button, modal, wrapper);
      });
    });
  }

  open(button, modal, wrapper) {
    disableShortcuts()
    wrapper.classList.add('block');
    wrapper.classList.remove('hidden');
    modal.focus();
  }

  close(button, modal, wrapper) {
    this.resetModalData();
    wrapper.classList.add('hidden');
    wrapper.classList.remove('block');
    enableShortcuts()
  }

  resetModalData() {
    const modal = document.querySelector(`#${this.element.id}`);

    const form = modal.querySelector('form');
    if (form) {
      form.style.display = 'block';

      const submitBtn = form
        .closest('.modal')
        .querySelector('button[type="submit"]');
      submitBtn.disabled = false;
      submitBtn.style.display = 'block';

      const paymentAmountEl = form.querySelector('input#payment_amount');
      if (paymentAmountEl) {
        paymentAmountEl.value = null;
      }
    }

    const iframe = modal.querySelector('iframe');
    if (iframe) {
      iframe.style.display = 'none';
      iframe.src = '';
    }
  }
}
