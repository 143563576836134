  import Rails from '@rails/ujs';

export const GET_SEARCH_INPUT_CALLBACK = 'getSearchInput'
export const ON_DATA_RECEIVED_CALLBACK = 'onDataReceived'
export const ON_DATA_REQUESTED_CALLBACK = 'onDataRequested'

const LIMIT_PARAM = 'limit'
const PAGE_PARAM = 'page'
const SEARCH_PARAM = 'search'
const SORT_ORDER_PARAM = 'sort_order[]'
const SORT_PARAM = 'sort[]'

function cloneURL(url) {
  return new URL(url.toString())
}

function mergeURLs(sourceUrl, originalTargetUrl, key) {
  const targetUrl = cloneURL(originalTargetUrl)
  targetUrl.searchParams.delete(key)
  sourceUrl.searchParams.getAll(key).forEach(value => targetUrl.searchParams.append(key, value))
  return targetUrl
}

export function replaceSortParams(sourceUrl, targetUrl) {
  targetUrl = mergeURLs(sourceUrl, targetUrl, SORT_PARAM)
  targetUrl = mergeURLs(sourceUrl, targetUrl, SORT_ORDER_PARAM)
  return targetUrl
}

export class Datasource {
  constructor({
    callbacks,
    indexPath,
    limit
  }) {
    this.callbacks = callbacks
    this.indexUrl = this.#parseIndexUrl(indexPath)
    this.limit = parseInt(limit)

    this.filterModel = null
    this.sortModel = null
  }

  getRows(params) {
    const url = this.#buildUrl(params)

    // window.history.replaceState({}, SEARCH_PARAM, url)

    // if(this.#isModelChanged(params)) {
    //   window.location.reload()
    // }
    this.#maybeRunCallback(ON_DATA_REQUESTED_CALLBACK, cloneURL(url))

    Rails.ajax({
      type: 'GET',
      success: (data, _status, _xhr) => {
        params.successCallback(
          data.records,
          data.next_page ? null : (Math.floor(params.endRow/this.limit) - 1) * this.limit + data.records.length
        )
        this.#maybeRunCallback(ON_DATA_RECEIVED_CALLBACK, data)
      },
      url
    });

    this.filterModel = params.filterModel
    this.sortModel = params.sortModel
  }

  #buildUrl(params) {
    const url = new URL(this.indexUrl)

    if (this.#parseSearchText()) {
      url.searchParams.append(SEARCH_PARAM, this.#parseSearchText())
    }

    Object.entries(params.filterModel).forEach(([field, data]) => {
      Object.entries(data).forEach(([filter, value]) => {
        url.searchParams.set(`${field}[${filter}]`, value)
      })
    })

    params.sortModel.forEach(sort => {
      url.searchParams.append(SORT_PARAM, sort.colId)
      url.searchParams.append(SORT_ORDER_PARAM, sort.sort)
    })

    url.searchParams.set(LIMIT_PARAM, this.limit)
    url.searchParams.set(PAGE_PARAM, this.#isModelChanged(params) ? 1 : this.#nextPage(params))

    return url
  }

  #isModelChanged(params) {
    return this.filterModel != null
      && this.sortModel != null
      && (this.filterModel != params.filterModel)
      && (this.sortModel != params.sortModel)
  }

  #maybeRunCallback(callbackName, ...args) {
    return this.callbacks[callbackName]?.apply(this.callbacks, args)
  }

  #nextPage(params) {
    return Math.ceil(params.endRow/this.limit)
  }

  #parseIndexUrl(indexPath) {
    return indexPath ? new URL(indexPath, document.location.origin).toString() : document.location.href
  }

  #parseSearchText() {
    return this.#maybeRunCallback(GET_SEARCH_INPUT_CALLBACK)?.trim()
  }
}
