import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { uncheck } from 'core/utils/helpers';
import {
  formatAmount,
  formatXTime,
} from 'core/utils/paymentsHelpers';

export default class extends Controller {
  connect() {
    const barChartsHeight = '200px';
    const stepTimeFormat = this.element.dataset.stepTimeFormat;
    const paymentsPath = this.element.dataset.paymentsPath;

    // customize charts
    const transactionsChart = Chartkick.charts['dashboard-transactions-chart'];
    if (transactionsChart) {
      transactionsChart.chart.canvas.parentNode.style.height = barChartsHeight;

      const options = transactionsChart.getOptions();
      options.library = {
        scales: {
          x: {
            ticks: {
              callback(val, index) {
                return index % 2 === 0
                  ? formatXTime(
                    this.getLabelForValue(val),
                    stepTimeFormat,
                  )
                  : '';
              },
            },
          },
        },
        onClick: (evt) => {
          this.parseDatesAndShowPayments(evt, paymentsPath);
        },
      };

      transactionsChart.setOptions(options);
    }
    const gmvBarChart = Chartkick.charts['gmv-bar-chart'];
    if (gmvBarChart && gmvBarChart.chart) {
      gmvBarChart.chart.canvas.parentNode.style.height = barChartsHeight;

      const options = gmvBarChart.getOptions();
      options.library = {
        scales: {
          x: {
            ticks: {
              callback(val, index) {
                return index % 2 === 0
                  ? formatXTime(
                    this.getLabelForValue(val),
                    stepTimeFormat,
                  )
                  : '';
              },
            },
          },
          y: {
            ticks: {
              callback(number, _index) {
                return formatAmount(number);
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += formatAmount(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
        onClick: (evt) => {
          this.parseDatesAndShowPayments(evt, paymentsPath);
        },
      };

      gmvBarChart.setOptions(options);
    }
  }

  parseDatesAndShowPayments(evt, paymentsPath) {
    const points = evt.chart.getElementsAtEventForMode(
      evt,
      'nearest',
      { intersect: true },
      true,
    );

    if (points.length) {
      const params = {
        from_date: evt.chart.data.labels[points[0].index],
        to_date: evt.chart.data.labels[points[0].index + 1] || '',
        strict_range: true,
      };
      window.location.assign(`${paymentsPath}?${new URLSearchParams(params).toString()}`);
    }
  }
}
