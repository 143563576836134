import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';

import {Datasource} from 'core/grid/datasource';
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';

const columnOptions = {
  columnDefs: {
    end_timestamp: { cellClass: 'font-mono cursor-pointer', maxWidth: 300, sortable: false },
    file_name: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'flexCellRenderer', sortable: false },
    start_timestamp: { cellClass: 'font-mono cursor-pointer', maxWidth: 300, sortable: false },
    status: { cellClass: 'cursor-pointer', maxWidth: 55, sortable: false, withoutHeader: true },
  },
  cookiesName: 'batch_payments_columns_positions',
  defaultSorting: [
    'status',
    'file_name',
    'start_timestamp',
    'end_timestamp'
  ],
};

function buildGrid(gridElement, dataCallbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new Datasource({ limit: paginationPageSize, callbacks: dataCallbacks }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) }
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#batchPaymentsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {})
    }
  }


  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
