import { Controller } from 'stimulus';
// import Rails from '@rails/ujs';
import * as AgGrid from 'ag-grid-community';
import {Datasource} from 'core/grid/datasource';
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';

const columnOptions = {
  columnDefs: {
    report_id: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false, tooltipField: 'report_id', },
    start_date: { cellClass: 'font-mono', sortable: false },
    end_date: { cellClass: 'font-mono', sortable: false },
    items_size: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    account_name: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false, tooltipField: 'account_name' },
    actions: { cellClass: 'ag-right-aligned-cell font-mono', sortable: false, withoutHeader: true },
  },
  cookiesName: 'hellgate_settlements_reports_columns_positions',
  defaultSorting: [
    'report_id',
    'start_date',
    'end_date',
    'items_size',
    'account_name',
    'actions'
  ],
};

function buildGrid(gridElement, dataCallbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
  } = gridElement.dataset

  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new Datasource({ limit: paginationPageSize, callbacks: dataCallbacks }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#hellgateSettlementsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {})
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
