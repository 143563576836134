import moment from 'moment';

export function validateShowLastInputValue(showLastInputEl, timingsInputEl) {
  const maxShowLastMinutes = 60;
  const maxShowLastHours = 24;
  const maxShowLastDays = 7;
  const maxShowLastWeeks = 52;

  if (timingsInputEl.value === 'minutes' && showLastInputEl.value > maxShowLastMinutes) {
    showLastInputEl.value = maxShowLastMinutes;

  } else if (timingsInputEl.value === 'hours' && showLastInputEl.value > maxShowLastHours) {
    showLastInputEl.value = maxShowLastHours;

  } else if (timingsInputEl.value === 'days' && showLastInputEl.value > maxShowLastDays) {
    showLastInputEl.value = maxShowLastDays;

  } else if (timingsInputEl.value === 'weeks' && showLastInputEl.value > maxShowLastWeeks) {
    showLastInputEl.value = maxShowLastWeeks;
  }
}

export function formatAmount(value) {
  return new Intl.NumberFormat('en-US').format(value);
}

export function formatXTime(value, format) {
  if (isNaN(new Date(value))) {
    return value;
  }
  return moment.parseZone(value).format(format);
}
