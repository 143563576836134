export function setCookie(name, value, expiresIn) {
  let expires = '';
  if (expiresIn) {
    const date = new Date();
    date.setTime(date.getTime() + expiresIn);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function setCookieForever(name, value) {
  const days = 36500;
  const expiresIn = days * 24 * 60 * 60 * 1000;
  setCookie(name, value, expiresIn);
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
