import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const firstEventBtn = document.querySelectorAll('button.event-button')[0];
    if (firstEventBtn) {
      this.__activateBtn(firstEventBtn);

      const { provider } = firstEventBtn.dataset;
      this.__printProvider(provider);

      const data = JSON.parse(firstEventBtn.dataset.event);
      this.__printEventData(data);
    }
  }

  showEventInfo(event) {
    const btn = event.currentTarget;
    this.__activateBtn(btn);

    const { provider } = btn.dataset;
    this.__printProvider(provider);

    const data = JSON.parse(btn.dataset.event);
    this.__printEventData(data);
  }

  __activateBtn(btn) {
    const activeWrapper = document.querySelector(
      'button.event-button div.event-preview.border-primary-500',
    );

    if (activeWrapper) {
      activeWrapper.classList.add('border-gray-200');
      activeWrapper.classList.remove('border-primary-500');
      activeWrapper.classList.remove('shadow');
    }

    const wrapper = btn.querySelector('div.event-preview');

    wrapper.classList.remove('border-gray-200');
    wrapper.classList.add('border-primary-500');
    wrapper.classList.add('shadow');
  }

  __printProvider(value) {
    const wrapper = this.element.querySelector('span.provider');
    wrapper.innerHTML = value;
  }

  __printEventData(data) {
    const wrapper = this.element.querySelector('.event-details');
    let html = '';

    for (let [key, value] of Object.entries(data)) {
      if (typeof value === 'object') {
        value = `<pre><code>${JSON.stringify(value, null, 2)}</code></pre>`;
      }

      html = `${html
      }<div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pr-3">`
        + `<dt class="text-sm font-medium text-gray-500">${
          key
        }</dt>`
        + `<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">${
          value
        }</dd>`
        + '</div>';
    }

    wrapper.innerHTML = html;
  }
}
