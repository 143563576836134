import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let transactionBtn = null;
    const highlightedButton = document.querySelector('button.transaction-button[data-highlighted=true]');

    if(highlightedButton) {
      transactionBtn = highlightedButton;
    } else {
      transactionBtn = document.querySelectorAll('button.transaction-button[data-transaction]')[0];
    }

    if (transactionBtn) {
      this.__activateBtn(transactionBtn);

      this.__printProvider(transactionBtn.dataset);

      this.__printTransactionData(transactionBtn.dataset);
    }
  }

  showTransactionInfo(transaction) {
    const btn = transaction.currentTarget;
    this.__activateBtn(btn);

    this.__printProvider(btn.dataset);

    this.__printTransactionData(btn.dataset);
  }

  showEventInfo(event) {
    const btn = event.currentTarget;
    this.__activateBtn(btn);

    this.__printProvider(btn.dataset);

    this.__printTransactionData(btn.dataset);
  }

  __activateBtn(btn) {
    const activeWrapper = document.querySelector(
      'button div.transaction-preview.border-primary-500',
    );

    if (activeWrapper) {
      activeWrapper.classList.add('border-gray-200');
      activeWrapper.classList.remove('border-primary-500');
      activeWrapper.classList.remove('shadow');
    }

    const wrapper = btn.querySelector('div.transaction-preview');

    wrapper.classList.remove('border-gray-200');
    wrapper.classList.add('border-primary-500');
    wrapper.classList.add('shadow');
  }

  __printProvider(dataset) {
    const wrapper = this.element.querySelector('span.provider');
    wrapper.innerHTML = dataset.provider;
  }

  __printTransactionData(dataset) {
    const data = JSON.parse(dataset.transaction);

    const wrapper = this.element.querySelector('.transaction-details');
    let html = '';

    for (let [key, value] of Object.entries(data)) {
      if (typeof value === 'object') {
        value = '<pre>'
          + `<code>${
            JSON.stringify(value, null, 2)
          }</code>`
          + '</pre>';
      }

      html = `${html
      }<div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pr-3">`
        + `<dt class="text-sm font-medium text-gray-500">${
          key
        }</dt>`
        + `<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">${
          value
        }</dd>`
        + '</div>';
    }

    wrapper.innerHTML = html;
  }
}
