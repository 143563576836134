import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const formId = this.element.id;
    this.addSigneeButton = document.querySelector(`#${formId} #add-signee`);
    this.signeesLimit = parseInt(this.element.dataset.signeesLimit)

    this.addSigneeButton.addEventListener("click", () => {
      this.addSignee();
    });

    const removeSigneeButtons = document.querySelectorAll(
      `#${formId} .remove-signee`
    );

    removeSigneeButtons.forEach((button) => {
      button.addEventListener("click", () => {
        this.deleteSignee(button);
      });
    });
    this.toggleAddSigneeButton()
  }

  replaceBetween(origin, startIndex, endIndex, insertion) {
    return (
      origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
    );
  }

  correctNames(signee) {
    const elements = signee.querySelectorAll(
      "input[type='hidden'], input[type='text'], input[type='email']"
    );
    const solt = (Math.random() + 1).toString(36).substring(7);

    elements.forEach((element) => {
      const name = element.name;
      const newName = this.replaceBetween(
        name,
        name.indexOf("[#<") + 1,
        name.indexOf(">]") + 1,
        solt
      );
      element.name = newName;
    });
  }

  addSignee() {
    const wrapEl = document.querySelector("#payment-method-setup-form-wrap");
    const newSigneeWrap = wrapEl.querySelector(
      "#new-signee-form .signee"
    );
    const signeesWrap = wrapEl.querySelector("#signees");

    let signee = newSigneeWrap.cloneNode(true);
    signee.classList.add("signee");
    signee.classList.remove("hidden");
    signee.removeAttribute("id");
    signeesWrap.append(signee);

    this.correctNames(signee);

    const removeSigneeButton = signee.querySelector(".remove-signee");
    removeSigneeButton.addEventListener("click", () => {
      this.deleteSignee(removeSigneeButton);
    });
    this.toggleAddSigneeButton()
  }

  deleteSignee(button) {
    if (confirm(button.dataset.alertText)) {
      button.parentElement.parentElement.remove()
    }
    this.toggleAddSigneeButton()
  }

  toggleAddSigneeButton() {
    const signeesCount = document.querySelectorAll("#signees .signee").length
    if (signeesCount >= this.signeesLimit) {
      this.addSigneeButton.classList.add("hidden");
    } else {
      this.addSigneeButton.classList.remove("hidden");
    }
  }
}
