export function disableElement(el) {
  el.disabled = true;
}

export function enableElement(el) {
  el.disabled = false;
}

export function uncheck(list) {
  for (let i = 0; i < list.length; i++) {
    list[i].checked = false;
  }
}
