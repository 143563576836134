import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const userFormComponent = document.querySelector(
      'form.new_user, form.edit_user',
    );

    if (userFormComponent) {
      const rolesCheckboxes = userFormComponent.querySelectorAll(
        'input[type=checkbox]',
      );

      rolesCheckboxes.forEach((roleCheckbox, i) => {
        roleCheckbox.addEventListener('change', (_event) => {
          const checked = userFormComponent.querySelector(
            'input[type=checkbox]:checked',
          );

          const submitBtn = userFormComponent.querySelector(
            'input[type=submit]',
          );

          if (checked) {
            submitBtn.disabled = false;
            return;
          }

          submitBtn.disabled = true;
        });
      });
    }
  }
}
