import { Controller } from 'stimulus';
import { addShortcut } from 'core/utils/shortcuts';

export default class extends Controller {
  connect() {
    addShortcut(this.element.getAttribute('shortcut'), () => this.element.click());

    this.disableAfterClick = this.element.dataset.disableAfterClick === 'true';
    this.clicked = false;

    if (this.disableAfterClick) {
      this.element.addEventListener('click', (event) => {
        if (this.clicked) {
          // If button has already been clicked, prevent further action
          event.preventDefault();
          return false;
        } else {
          this.disableButton();
          this.clicked = true; // Mark as clicked to prevent double submission
        }
      });
    }

    const form = this.element.closest('form');
    if (form) {
      form.addEventListener('input', () => {
        // Re-enable the button if it was previously clicked and the form had a validation error
        if (this.clicked) {
          this.element.disabled = false;
          this.clicked = false; // Reset the clicked state
        }
      });
    }
  }

  disableButton() {
    setTimeout(() => {
      this.element.disabled = true;
    }, 10); // Short delay to ensure form submission
  }
}
