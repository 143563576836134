import { Controller } from 'stimulus';

export default class extends Controller {
  createElAndCopy(value) {
    const body = document.querySelector('body');
    const area = document.createElement('textarea');
    body.appendChild(area);
    area.value = value;
    area.select();
    document.execCommand('copy');
    body.removeChild(area);
  }

  copyText(event) {
    const targetEl = event.currentTarget;
    const textEl = document.querySelector(`#${targetEl.dataset.id}`);

    if (textEl.type === 'text') {
      this.createElAndCopy(textEl.dataset.text);
    } else {
      this.createElAndCopy(textEl.innerText);
    }

    const copyIcon = targetEl.querySelector('svg.copy-icon');
    const copiedIcon = targetEl.querySelector('svg.copied-icon');

    copyIcon.classList.add('hidden');
    copiedIcon.classList.remove('hidden');

    setTimeout(() => {
      copiedIcon.classList.add('hidden');
      copyIcon.classList.remove('hidden');
    }, 1250);
  }

  toggleText(type, button) {
    const field = document.querySelector(`#${button.dataset.id}`);
    field.value = button.dataset.text;
    button.classList.add('hidden');
  }

  showText(event) {
    const button = event.currentTarget;

    this.toggleText('show', button);

    const hideBtn = document.querySelector('#hide-token-btn');
    hideBtn.classList.remove('hidden');
  }

  hideText(event) {
    const button = event.currentTarget;

    this.toggleText('hide', button);

    const hideBtn = document.querySelector('#show-token-btn');
    hideBtn.classList.remove('hidden');
  }
}
