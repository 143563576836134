import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // set state
    this.$modal = this.element;
    this.$form = this.$modal.querySelector('form');
    this.$submitBtn = this.$modal.querySelector('button[type="submit"]');
    this.options = {
      isReloadOnSuccess:
        this.$modal.getAttribute('data-success') == 'reload',
      url: this.$modal.getAttribute('data-url'),
    };
    // subscribe on events
    this.$submitBtn.addEventListener('click', this.onSubmit.bind(this));
    this.$modal.addEventListener('hidden.bs.modal', this.onClose.bind(this));
  }

  buildFormData() {
    const FD = new FormData();
    this.$form
      .querySelectorAll('input')
      .forEach(($input) => FD.append($input.name, $input.value));
    return FD;
  }

  onClose() {
    this.$submitBtn.disabled = false;
  }

  onSubmit(e) {
    e.preventDefault();
    this.$submitBtn.disabled = true;

    const XHR = new XMLHttpRequest();
    XHR.addEventListener('load', this.onResponse.bind(this));
    XHR.open('POST', this.options.url);
    XHR.send(this.buildFormData());
  }

  onResponse(event) {
    const response = JSON.parse(event.target.responseText);
    // handle success
    if (event.target.status == 200) {
      this.onClose();
      if (this.options.isReloadOnSuccess) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      // handle failure
    } else {
      this.$submitBtn.disabled = false;
      alert(response.error);
    }
  }
}

// // NOTE: onResponse method has to be improved with fancy alerts
//
// class ModalForm {
//   constructor($modal) {
//     // set state
//     this.$modal = $modal;
//     this.$form = $modal.querySelector("form");
//     this.$submitBtn = this.$form.querySelector('input[type="submit"]');
//     this.options = {
//       isReloadOnSuccess: $modal.getAttribute('data-on-success') == 'reload',
//       url: $modal.getAttribute('data-url')
//     }
//     // subscribe on events
//     this.$submitBtn.addEventListener('click', this.onSubmit.bind(this));
//     this.$modal.addEventListener('hidden.bs.modal', this.onClose.bind(this));
//   }
//
//   buildFormData() {
//     let FD = new FormData()
//     this.$form.querySelectorAll('input').forEach(($input) => FD.append($input.name, $input.value))
//     return FD
//   }
//
//   onClose() {
//     this.$submitBtn.disabled = false;
//   }
//
//   onSubmit(e) {
//     e.preventDefault();
//     this.$submitBtn.disabled = true
//
//     const XHR = new XMLHttpRequest();
//     XHR.addEventListener('load', this.onResponse.bind(this));
//     XHR.open('POST', this.options.url);
//     XHR.send(this.buildFormData());
//   }
//
//   onResponse (event) {
//     const response = JSON.parse(event.target.responseText);
//     // handle success
//     if (event.target.status == 200) {
//       this.onClose()
//       alert(response['message'])
//       if(this.options.isReloadOnSuccess) {
//         window.location.reload()
//       }
//     // handle failure
//     } else {
//       this.$submitBtn.disabled = false
//       alert(response['error'])
//     }
//   }
// }
//
// document.addEventListener("turbolinks:load", function () {
//   document.querySelectorAll(".u-modal-form").forEach((element) => new ModalForm(element));
// });
