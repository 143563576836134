import { Controller } from "stimulus";
import { enableElement, disableElement } from "core/utils/helpers";

export default class extends Controller {
  connect() {
    this.$modalEl = this.element;
    this.$sessionKey = null;

    this.$cardNumberElementId = "payment-card-number";
    this.$cardNumberElement = this.$modalEl.querySelector(
      `#${this.$cardNumberElementId}`
    );

    this.$cvc2ElementId = "payment-cvv";
    this.$cvc2Element = this.$modalEl.querySelector(
      `#${this.$cvc2ElementId}`
    );

    this.$paymentCardholderNameInput = 'input[name="payment[cardholder_name]"]';

    this.$paymentSuccessMessage = this.$modalEl.querySelector(
      "#payment-success-message"
    );

    this.$paymentErrors = this.$modalEl.querySelector(
      "#payment-errors"
    );

    this.$createPaymentForm = this.$modalEl.querySelector(
      "form#smbc-create-payment-form"
    );
    this.$paymentAmount = this.$createPaymentForm.querySelector(
      'input[name="payment[amount]"]'
    );
    this.$paymentOrderId = this.$createPaymentForm.querySelector(
      'input[name="payment[order_id]"]'
    );
    this.$processCardForm = this.$modalEl.querySelector(
      "form#smbc-process-card-form"
    );
    this.$createPaymentBtn = this.$modalEl.querySelector(
      "button#smbc-create-payment"
    );
    this.$processCardBtn = this.$modalEl.querySelector(
      "button#smbc-process-card"
    );

    this.$closeModalBtns = this.$modalEl.querySelectorAll("button.modal-close");

    this.$createPaymentBtn.addEventListener(
      "click",
      this.createPayment.bind(this)
    );
    this.$processCardBtn.addEventListener("click", this.processCard.bind(this));

    this.$closeModalBtns.forEach((button) => {
      button.addEventListener("click", () => {
        this.closeModal();
      });
    });
  }

  closeModal() {
    this.$paymentSuccessMessage.style.display = "none";

    this.hidePaymentErrors();

    this.resetFormFields([this.$paymentAmount, this.$paymentOrderId]);

    this.$createPaymentForm.style.display = "block";
    this.$createPaymentBtn.style.display = "block";

    this.$processCardForm.style.display = "none";
    this.$processCardBtn.style.display = "none";

    this.$cardNumberElement.innerHTML = "";
    this.$cvc2Element.innerHTML = "";
  }

  resetFormFields(fields) {
    fields.filter((f) => f).forEach((f) => (f.value = null));
  }

  showPaymentErrors (message) {
    this.$paymentErrors.innerHTML = message;
    this.$paymentErrors.style.display = "block";
  }

  hidePaymentErrors () {
    this.$paymentErrors.style.display = "none";
    this.$paymentErrors.innerHTML = "";
  }

  createPayment() {
    this.hidePaymentErrors();

    enableElement(this.$createPaymentBtn);

    disableElement(this.$createPaymentBtn);
    this.$closeModalBtns.forEach((button) => {
      disableElement(button);
    });

    const XHR = new XMLHttpRequest(),
      FD = new FormData(),
      $authToken = this.$createPaymentForm.querySelector(
        'input[name="authenticity_token"]'
      );

    // if (!this.$paymentAmount.value || !this.$paymentOrderId.value) {
    //   enableElement(this.$createPaymentBtn);
    //   this.$closeModalBtns.forEach((button) => {
    //     enableElement(button);
    //   });
    //   return;
    // }

    // Push our data into our FormData object
    FD.append("payment[amount]", this.$paymentAmount.value);
    FD.append("payment[order_id]", this.$paymentOrderId.value);

    // Define what happens on successful data submission
    XHR.addEventListener("load", this.onCreatePaymentResponse.bind(this));

    // Set up our request
    const url =
      this.$createPaymentForm.action +
      "?&authenticity_token=" +
      $authToken.value;
    XHR.open("POST", url);

    // Send our FormData object; HTTP headers are set automatically
    XHR.send(FD);
  }

  onCreatePaymentResponse(event) {
    let response = {};
    try {
      response = JSON.parse(event.target.responseText);
    } catch (error) {
      this.$closeModalBtns.forEach((button) => {
        enableElement(button);
      });
      enableElement(this.$createPaymentBtn);
      this.showPaymentErrors("Unable initialize payment. Please contact support.");
      this.$createPaymentBtn.focus()
      return;
    }

    this.$closeModalBtns.forEach((button) => {
      enableElement(button);
    });

    if (event.target.status == 200) {
      this.$sessionKey =
        response.data.processing.requirement.options[0].session_key;
      this.$createPaymentForm.style.display = "none";
      this.$createPaymentBtn.style.display = "none";
      this.resetFormFields([this.$paymentAmount, this.$paymentOrderId]);
      this.$processCardForm.style.display = "block";
      this.$processCardBtn.style.display = "block";
      this.$modalEl.querySelector(this.$paymentCardholderNameInput).focus()
      this.initCheckout();
    } else {
      enableElement(this.$createPaymentBtn);
      this.showPaymentErrors(response.error);
      this.$createPaymentBtn.focus()
    }
  }

  initCheckout() {
    this.hidePaymentErrors();
    const context = this;
    const inputStyles = `
      body {
        margin-right: 28px !important;
      }
      input {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        font-family: system-ui;
        border: 1px solid #d4d4d4 !important;
        border-radius: 0.375rem !important;
        padding: 0 10px !important;
        margin: 2px 0 0 3px !important;
        height: 38px !important;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
      }
      input:focus {
        border: 1px solid white !important;
        outline: 2px solid rgb(59 130 246 / 1) !important;
      }
    `;
    this.$dtPay = CheckoutExperience.init(
      this.$sessionKey,
      {
        cardNumberElement: this.$cardNumberElementId,
        cardNumberStyles: inputStyles,
        cvc2Element: this.$cvc2ElementId,
        cvc2Styles: inputStyles,
      },
      () => {}
    );
    this.$dtPay.wip(() => {});
    this.$dtPay.errors((errors) => {
      const mErrors = errors.map(
        (err) => err.key + " | " + err.attribute + " | " + err.message
      );
      this.showPaymentErrors(mErrors);
      enableElement(context.$processCardBtn);
      context.$closeModalBtns.forEach((button) => {
        enableElement(button);
      });
      context.$processCardBtn.focus();
    });
    this.$dtPay.success(() => {
      this.hidePaymentErrors();
      context.$processCardForm.style.display = "none";
      context.$processCardBtn.style.display = "none";
      context.resetFormFields([
        context.$paymentCardholderName,
        context.$paymentMonth,
        context.$paymentYear,
      ]);
      context.$paymentSuccessMessage.style.display = "block";
      enableElement(context.$processCardBtn);
      context.$closeModalBtns.forEach((button) => {
        enableElement(button);
      });
      context.$closeModalBtns[context.$closeModalBtns.length - 1].focus()
    });
    this.$dtPay.validation((status) => {});
  }

  processCard() {
    disableElement(this.$processCardBtn);
    this.$closeModalBtns.forEach((button) => {
      disableElement(button);
    });

    this.$paymentCardholderName = this.$processCardForm.querySelector(
      this.$paymentCardholderNameInput
    );
    this.$paymentMonth = this.$processCardForm.querySelector(
      'input[name="payment[month]"]'
    );
    this.$paymentYear = this.$processCardForm.querySelector(
      'input[name="payment[year]"]'
    );

    if (
      !this.$paymentCardholderName.value ||
      !this.$paymentMonth.value ||
      !this.$paymentYear.value
    ) {
      enableElement(this.$processCardBtn);
      this.$closeModalBtns.forEach((button) => {
        enableElement(button);
      });
      return;
    }

    let fields = {};
    fields["cardholder_name"] = this.$paymentCardholderName.value;
    fields["month"] = this.$paymentMonth.value;
    fields["year"] = this.$paymentYear.value;
    this.$dtPay.processCard(fields);
    return;
  }
}
