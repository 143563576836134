import { Controller } from 'stimulus';
import { togglePopoverHandler } from '../popover_controller';
import { validateShowLastInputValue } from 'core/utils/paymentsHelpers';

const NUMBER_OF_MONTHS = 1;
const MAX_DAYS = 365;

export const DATE_RANGES_BUTTON_ID = 'date-ranges-btn';
export const DATE_RANGES_POPOVER_PANEL_ID = 'date-ranges-popover-panel';

export function showDateRangesPopover() {
  togglePopoverHandler(
    document.getElementById(DATE_RANGES_BUTTON_ID),
    document.getElementById(DATE_RANGES_POPOVER_PANEL_ID),
  );
}

export default class extends Controller {
  connect() {
    this.applyButtonElements = this.element.querySelectorAll('.apply-date-ranges-btn');
    this.fromDateElement = this.element.querySelector('#from_date');
    this.periodElement = this.element.querySelector('#period');
    this.showLastElement = this.element.querySelector('#show_last');
    this.timingsElement = this.element.querySelector('#timings');
    this.toDateElement = this.element.querySelector('#to_date');

    if (this.fromDateElement && this.toDateElement) {
      this.pickerObject = this.__initDatePicker()
    }

    const $dateRangesBtnEl = document.getElementById(DATE_RANGES_BUTTON_ID);
    const $dateRangesWrapperEl = document.getElementById('date-ranges-wrapper');

    if ($dateRangesBtnEl && $dateRangesWrapperEl) {
      this.__subscribeOnApplyDateRanges()

      $dateRangesBtnEl.addEventListener('click', (event) => {
        event.preventDefault();
      });
    }

    if (this.showLastElement) {
      this.showLastElement.addEventListener('change', () => {
        this.__castShowLast()
        validateShowLastInputValue(this.showLastElement, this.timingsElement);
      });
    }

    if (this.timingsElement) {
      this.timingsElement.addEventListener('change', () => {
        validateShowLastInputValue(this.showLastElement, this.timingsElement);
      });
    }
  }

  __castShowLast() {
    if (this.showLastElement.value < 0) {
      this.showLastElement.value = -this.showLastElement.value;
    }

    if (this.showLastElement.value % 1 !== 0) {
      this.showLastElement.value = Math.trunc(this.showLastElement.value);
    }

    this.showLastElement.value = this.showLastElement.value.replace(/[^\d]/, '');
  }

  __initDatePicker() {
    const controller = this
    return new Litepicker({
      element: controller.fromDateElement,
      elementEnd: controller.toDateElement,
      singleMode: false,
      allowRepick: true,
      inlineMode: true,
      numberOfMonths: NUMBER_OF_MONTHS,
      maxDays: MAX_DAYS,
      maxDate: new Date(),
      setup: (picker) => {
        picker.on('selected', (_date1, _date2) => {
          controller.periodElement.value = null;
          controller.showLastElement.value = null;
          controller.timingsElement.value = null;
        });
      },
    })
  }

	__subscribeOnApplyDateRanges() {
    const controller = this
    this.applyButtonElements.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        if (controller.showLastElement.value && controller.showLastElement.value < 1) {
          return;
        }
        event.target.closest('form').submit()
      });
    })
	}

  disconnect() {
    if (this.pickerObject) {
      this.pickerObject.destroy();
    }
  }
}
