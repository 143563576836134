const HTML_TAGS_WITHOUT_KEYPRESS = [
  'INPUT',
  'TEXTAREA'
];

let listeners = []

function addEventListener(element, type, shortcut, listener) {
  element.addEventListener(type, listener);
  listeners.push({ element, listener, shortcut, type });
}

function addShortcut(shortcut, handler) {
  if (typeof shortcut != 'string') return;
  if (shortcut.trim().length == 0) return;

  addEventListener(document, 'keyup', shortcut, (event) => {
     if (HTML_TAGS_WITHOUT_KEYPRESS.includes(document.activeElement.tagName)) return;

     if (event.key === shortcut || event.key === shortcut.toUpperCase()) {
       event.preventDefault();
       handler(event)
     }
  })
}

function disableShortcuts() {
  listeners.forEach(({element, type, listener}) => { element.removeEventListener(type, listener) })
}

function enableShortcuts() {
  listeners.forEach(({element, type, listener}) => { element.addEventListener(type, listener) })
}

// KEYS
function isEscPressed(event) { return event.key === 'Escape' || event.keyCode === 27; }
function isTabPressed(event) { return event.key === 'Tab' || event.keyCode === 9 }

export {
  addShortcut,
  disableShortcuts,
  enableShortcuts,
  isEscPressed,
  isTabPressed
}
