import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const iframe = document.getElementById('kibana-dashboard');

    iframe.addEventListener('load', this.hideExtraElements);
    this.removeExtraElements.bind(iframe)();
    iframe.contentWindow.addEventListener(
      'resize',
      this.removeExtraElements.bind(iframe),
    );
  }

  hideExtraElements(event) {
    const extraStyles = [
      '.dscSidebar__indexPatternSwitcher { display: none; }', // index pattern switch
      '.euiHeader.header__firstBar { display: none; };', // header
      '.euiHeaderSection.euiHeaderSection--left { display: none; }', // main menu
      '.euiPopover__anchor { display: none; }', // popover anchor
      '.header__toggleNavButtonSection { display: none; }', // toggle primary navigation
      "button[data-test-subj='discoverOpenButton'] { display: none; }", // open search button
      "button[data-test-subj='shareTopNavButton'] { display: none; }", // share search button
      'nav.euiHeaderBreadcrumbs { display: none; }', // breadcrumbs
    ];

    const style = document.createElement('style');
    style.textContent = extraStyles.join('\n');
    event.target.contentDocument.head.appendChild(style);
  }

  removeExtraElements() {
    const extraSelectors = [
      '.dscSidebar__indexPatternSwitcher', // index pattern switch
      '.header__firstBar', // header
      'button[data-test-subj="shareTopNavButton"]', // share search button
      'button[data-test-subj="discoverOpenButton"]', // open searches button
    ];
    extraSelectors.forEach((selector) => {
      const interval = setInterval(() => {
        const el = this.contentDocument.querySelectorAll(selector)[0];
        if (el) {
          el.remove();
          clearInterval(interval);
        }
      }, 200);
    });
  }
}
