import { Controller } from 'stimulus';
// import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';
import {Datasource} from 'core/grid/datasource';
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';


const columnOptions = {
  columnDefs: {
    event: { cellClass: 'font-mono cursor-pointer', sortable: false },
    item: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'flexCellRenderer', sortable: false },
    user: { cellClass: 'font-mono cursor-pointer', sortable: false },
    executed: { cellClass: 'font-mono cursor-pointer', sortable: false },
    item_link: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'linkCellRenderer', sortable: false },
  },
  cookiesName: 'audit_trails_columns_positions',
  defaultSorting: [
    'event',
    'item',
    'user',
    'executed',
    'item_link'
  ],
};

function buildGrid(gridElement, dataCallbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
  } = gridElement.dataset;
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new Datasource({ limit: paginationPageSize, callbacks: dataCallbacks }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
  };
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#auditTrailsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {});
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
