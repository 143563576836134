import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const chartsHeight = '200px';
    const paymentsChart = Chartkick.charts['payments-chart'];

    if (paymentsChart) {
      paymentsChart.chart.canvas.parentNode.style.height = chartsHeight;

      const options = paymentsChart.getOptions();

      options.library = {
        scales: {
          x: {
            ticks: {
              callback(val, index) {
                return index % 2 === 0 ? this.getLabelForValue(val) : '';
              },
            },
          },
        },
      };

      paymentsChart.setOptions(options);
    }

    const transactionsChart = Chartkick.charts['transactions-chart'];

    if (transactionsChart) {
      transactionsChart.chart.canvas.parentNode.style.height = chartsHeight;
    }
  }
}
