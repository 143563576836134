export class DefaultCellRenderer {
  constructor() {}

  init(params) {
    this.eGui = document.createElement('div');
    if (!params.value) {
      return;
    }
    this.eGui.innerHTML = params.value;
  }

  getGui() {
    return this.eGui;
  }
}
