import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import * as AgGrid from 'ag-grid-community';
import { togglePopoverHandler } from './popover_controller';
import { isEscPressed } from 'core/utils/shortcuts';
import { addShortcut } from 'core/utils/shortcuts';

export default class extends Controller {
  connect() {
    const searchHintOverlay = this.element.parentElement.querySelector(
      '.search-hint-overlay',
    );
    const searchHintOverlaySubmitButton = this.element.parentElement.querySelector(
      '#submit-transactions-search-btn',
    );
    const searchHint = this.element.parentElement.querySelector(
      '.search-results-preview',
    );

    if (searchHintOverlay) {
      this.element.addEventListener('focus', (event) => {
        searchHintOverlay.classList.remove('hidden');
        searchHintOverlay.classList.add('block');
        searchHintOverlaySubmitButton.setAttribute('aria-selected', 'true');
      });

      this.element.addEventListener('blur', (event) => {
        searchHintOverlay.classList.remove('block');
        searchHintOverlay.classList.add('hidden');
        searchHintOverlaySubmitButton.setAttribute('aria-selected', 'false');
      });

      this.element.addEventListener('keydown', (event) => {
        if (isEscPressed(event)) {
          this.element.blur()
        }
      });

      searchHintOverlaySubmitButton.addEventListener('mouseleave', (event) => {
        searchHintOverlaySubmitButton.setAttribute('aria-selected', 'false');
      });

      searchHintOverlaySubmitButton.addEventListener('mousedown', (event) => {
        document.forms.headerSearchForm.submit();
      });

      if (document.forms.headerSearchForm) {
        const searchInput = document.forms.headerSearchForm.querySelector('#input_search');
        searchInput.addEventListener('input', updateValue);
        function updateValue(e) {
          searchHint.textContent = e.target.value;
        }
      }
    }

    addShortcut(this.element.getAttribute('shortcut'), () => this.element.focus());

    // -------------------------

    const { filterOpened, onlyPayments, onlyTransactions } = this.element.dataset

    if (filterOpened === 'true' && (onlyTransactions === 'true' || onlyPayments === 'true')) {
      const button = this.element.querySelector('#filter-popover-open-filters-btn');
      const panel = button.nextSibling;
      togglePopoverHandler(button, panel);
    }
  }
}
