import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('li[data-active]').forEach(el => el.scrollIntoView(false))
  }

  makeActive(event) {
    const activeClass = 'bg-gray-100';

    this.element
      .querySelector(`li.${activeClass}`)
      .classList.remove(activeClass);

    event.currentTarget.classList.add(activeClass);
  }
}
