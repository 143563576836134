import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const formId = this.element.id;
    const addComponentButton = document.querySelector(
      `#${formId} #add-component`
    );

    addComponentButton.addEventListener("click", () => {
      this.addComponent(addComponentButton);
    });

    const removeComponentButtons = document.querySelectorAll(
      `#${formId} .remove-bundle-component`
    );

    removeComponentButtons.forEach((button) => {
      button.addEventListener("click", () => {
        this.deleteComponent(button);
      });
    });
  }

  replaceBetween(origin, startIndex, endIndex, insertion) {
    return (
      origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
    );
  }

  correctNames(component) {
    const elements = component.querySelectorAll(
      "input[type='hidden'], input[type='text'], select, textarea"
    );
    const solt = (Math.random() + 1).toString(36).substring(7);

    elements.forEach((element) => {
      const name = element.name;
      const newName = this.replaceBetween(
        name,
        name.indexOf("[#<") + 1,
        name.indexOf(">]") + 1,
        solt
      );
      element.name = newName;
    });

    const checkbox = component.querySelector("input[type='checkbox']");
    const name = checkbox.name;
    const newName = this.replaceBetween(
      name,
      name.indexOf("s][") + 3,
      name.indexOf("][o"),
      `${solt}`
    );
    checkbox.name = newName;

    this.initTrixEditor(component, solt);
  }

  initTrixEditor(component, solt) {
    const trixWrap = component.querySelector(".trix-editor-wrap");
    const newId = `component-${solt}-description`;

    const field = trixWrap.querySelector("input[type='hidden']");
    field.id = newId;

    const trixEditor = document.createElement("trix-editor");
    trixEditor.setAttribute("input", newId);
    trixWrap.append(trixEditor, field);
  }

  addComponent(button) {
    const wrapEl = document.querySelector("#bundle-form-wrap");
    const newComponentWrap = wrapEl.querySelector(
      "#new-component-form .component"
    );
    const componentsWrap = wrapEl.querySelector("#components");

    let component = newComponentWrap.cloneNode(true);
    component.classList.add("component");
    component.classList.remove("hidden");
    component.removeAttribute("id");
    componentsWrap.append(component);

    this.correctNames(component);

    const removeComponentButton = component.querySelector(
      ".remove-bundle-component"
    );
    removeComponentButton.addEventListener("click", () => {
      this.deleteComponent(removeComponentButton);
    });
  }

  deleteComponent(button) {
    if (confirm(button.dataset.alertText)) {
      const componentEl = button.parentElement.parentElement;
      const componentId = componentEl.dataset.componentId;
      if (componentId) {
        const destroyFlagInput = componentEl.querySelector(
          `input[name="bundle[components][${componentId}][_destroy]"]`
        );
        destroyFlagInput.value = "1";
        componentEl.classList.add("hidden");
      } else {
        componentEl.remove();
      }
    }
  }
}
