export class FlexCellRenderer {
  constructor() {}

  init(params) {
    this.eGui = document.createElement('div');
    if (!params.value) {
      return;
    }
    this.eGui.classList = 'flex items-center max-w-xs';
    this.eGui.innerHTML = params.value;
  }

  getGui() {
    return this.eGui;
  }
}
