import { Controller } from 'stimulus';
// import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';
import {
  ON_DATA_REQUESTED_CALLBACK,
  Datasource,
  replaceSortParams
} from 'core/grid/datasource';

import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';

const columnOptions = {
  columnDefs: {
    date: { cellClass: 'font-mono cursor-pointer', maxWidth: 250, sortable: true },
    fingerprint: { cellClass: 'font-mono cursor-pointer', maxWidth: 150 },
    message: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'flexCellRenderer' },
    status: { cellClass: 'cursor-pointer', maxWidth: 55, sortable: true, withoutHeader: true },
  },
  cookiesName: 'transactions_columns_positions',
  defaultSorting: [
    'status',
    'date',
    'fingerprint',
    'message',
  ],
};

function buildGrid(gridElement, dataCallbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    indexPath,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson)
    }),
    datasource: new Datasource({
      callbacks: dataCallbacks,
      indexPath,
      limit: paginationPageSize,
    }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const exportLinkElement = this.element.querySelector('#export-transactions-link');
    const gridElement = this.element.querySelector('#transactionsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {
        [ON_DATA_REQUESTED_CALLBACK](dataUrl) {
          if (exportLinkElement) {
            exportLinkElement.href = replaceSortParams(dataUrl, exportLinkElement.href)
          }
        }
      })
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
