import { Controller } from 'stimulus';
import { uuidv4 } from 'core/utils/uuid';

export default class extends Controller {
  connect() {
    if (!this.element.hasAttribute('id')) {
      this.element.setAttribute('id', `accordionElement${uuidv4()}`);
    }
    this.update();
  }

  update() {
    const buttons = document.querySelectorAll(`#${this.element.id} button.accordion-button`);
    const bodies = document.querySelectorAll(`#${this.element.id} .accordion-body`);

    buttons.forEach((button, i) => {
      if (!button.id) {
        button.setAttribute('id', `${this.element.id}Button${i}`);
        bodies[i].setAttribute(
          'aria-labelledby',
          `${this.element.id}Button${i}`,
        );
      } else {
        bodies[i].setAttribute('aria-labelledby', button.id);
      }
      button.setAttribute('aria-controls', `${this.element.id}Body${i}`);
      button.setAttribute('aria-haspopup', 'true');
      button.setAttribute('aria-expanded', 'false');
      bodies[i].setAttribute('id', `${this.element.id}Body${i}`);

      if (this.element.classList.contains('is-open')) {
        this.open(button, bodies[i]);
      }

      button.addEventListener('click', () => {
        this.toggleHandler(button, bodies[i]);
      });
    });
  }

  toggleHandler(button, body) {
    if (body.classList.contains('hidden')) {
      this.open(button, body);
    } else {
      this.close(button, body);
    }
  }

  replaceInnerText(button, text) {
    if(!button.dataset.text || !button.dataset.openTextReplace) {
      return;
    }

    button.innerHTML = text;
  }

  open(button, body) {
    body.classList.add('block');
    body.classList.remove('hidden');
    button.setAttribute('aria-expanded', 'true');
    button.parentNode.classList.add('is-open');
    this.replaceInnerText(button, button.dataset.openTextReplace)
    body.focus();
  }

  close(button, body) {
    body.classList.add('hidden');
    body.classList.remove('block');
    button.setAttribute('aria-expanded', 'false');
    this.replaceInnerText(button, button.dataset.text)
    button.parentNode.classList.remove('is-open');
  }
}
