import { Controller } from 'stimulus';
import { uuidv4 } from 'core/utils/uuid';
import { isEscPressed } from 'core/utils/shortcuts';

export function togglePopoverHandler(button, panel) {
  if (panel.classList.contains('hidden')) {
    openPopover(button, panel);
  }
}

function openPopover(button, panel) {
  panel.classList.add('block');
  panel.classList.remove('hidden');
  button.setAttribute('aria-expanded', 'true');
  button.classList.add('ring-2', 'ring-offset-2', 'ring-primary-500');
  panel.focus();
  hide(panel, button, panel);
}

function closePopover(button, panel) {
  panel.classList.add('hidden');
  panel.classList.remove('block');
  button.classList.remove('ring-2', 'ring-offset-2', 'ring-primary-500');
  button.setAttribute('aria-expanded', 'false');
}

function hide(element, button, panel) {
  let firstClick = true;

  const outsideClickListener = (event) => {
    if (
      event.target.classList.contains('popover-close')
      || (!event.target.classList.contains('day-item')
        && !event.target.classList.contains('button-previous-month')
        && !event.target.classList.contains('button-next-month')
        && !element.contains(event.target)
        && isVisible(element)
        && !event.code
        && !firstClick)
    ) {
      closePopover(button, panel);
      removeListeners();
    }
    firstClick = false;
  };
  const escKeydownListener = (event) => {
    if (isVisible(element) && isEscPressed(event)) {
      closePopover(button, panel);
      removeListeners();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
    firstClick = true;
  };
  const removeEscKeydownListener = () => {
    document.removeEventListener('keydown', escKeydownListener);
  };
  const removeListeners = () => {
    removeClickListener();
    removeEscKeydownListener();
  };

  document.addEventListener('click', outsideClickListener);
  document.addEventListener('keydown', escKeydownListener);
}

function isVisible(elem) {
  return (
    !!elem
    && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
  );
}

export default class extends Controller {
  connect() {
    if (!this.element.hasAttribute('id')) {
      this.element.setAttribute('id', `popover-${uuidv4()}`);
    }
    this.update();
  }

  update() {
    const buttons = document.querySelectorAll(`#${this.element.id} > button`);
    const panels = document.querySelectorAll(
      `#${this.element.id} .popover-panel`,
    );

    buttons.forEach((button, i) => {
      if (!button.id) {
        button.setAttribute('id', `${this.element.id}Button${i}`);
        panels[i].setAttribute(
          'aria-labelledby',
          `${this.element.id}Button${i}`,
        );
      } else {
        panels[i].setAttribute('aria-labelledby', button.id);
      }
      button.setAttribute('aria-controls', `${this.element.id}Panel${i}`);
      button.setAttribute('aria-haspopup', 'true');
      button.setAttribute('aria-expanded', 'false');
      if (!panels[i].hasAttribute('id')) {
        panels[i].setAttribute('id', `${this.element.id}Panel${i}`);
      }

      button.addEventListener('click', () => {
        togglePopoverHandler(button, panels[i]);
      });
    });
  }
}
