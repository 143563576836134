import { Controller } from 'stimulus';
// import Rails from '@rails/ujs';
import * as AgGrid from 'ag-grid-community';
import {Datasource} from 'core/grid/datasource';
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/grid/utils';

const columnOptions = {
  columnDefs: {
    file_name: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false, tooltipField: 'file_name', },
    consolidation_id: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    start_date: { cellClass: 'font-mono', maxWidth: 300, sortable: false },
    end_date: { cellClass: 'font-mono', maxWidth: 300, sortable: false },
    type: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    status: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    message: { cellClass: 'font-mono', sortable: false, resizable: true, width: 300, tooltipField: 'message' },
    actions: { cellClass: 'ag-right-aligned-cell font-mono', maxWidth: 120, sortable: false, withoutHeader: true },
  },
  cookiesName: 'dtfs_file_processing_consolidations_columns_positions',
  defaultSorting: [
    'file_name',
    'consolidation_id',
    'start_date',
    'end_date',
    'type',
    'status',
    'message',
    'actions'
  ],
};

function buildGrid(gridElement, dataCallbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new Datasource({ limit: paginationPageSize, callbacks: dataCallbacks }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
  }

  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  static targets = ['fileField'];

  connect() {
    this.$submitButton = document.querySelector('#submit-button-wrapper input[type=submit]');

    const gridElement = this.element.querySelector('#fileProcessingGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement, {})
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }

  selectFile(event) {
    event.preventDefault();
    this.fileFieldTarget.click();
  }

  submitForm() {
    if (this.fileFieldTarget.files.length > 0) {
      this.fileFieldTarget.form.submit();
    }
  }

  enableSubmitButton(event) {
    if (event.target.files.length > 0) {
      this.$submitButton.disabled = false;
    } else {
      this.$submitButton.disabled = true;
    }
  }
}
