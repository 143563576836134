import { Controller } from 'stimulus';
import Cropper from 'cropperjs';

export default class extends Controller {
  connect() {
    const accountNewLogoInput = document.getElementById('logo-input');
    const accountNewLogoImg = document.getElementById('logo-img');
    const logoCropX = document.getElementById('logo_crop_x');
    const logoCropY = document.getElementById('logo_crop_y');
    const logoCropW = document.getElementById('logo_crop_w');
    const logoCropH = document.getElementById('logo_crop_h');
    let cropper;

    accountNewLogoInput.addEventListener('change', (event) => {
      const newFile = event.target.files[0];

      if (newFile) {
        accountNewLogoImg.src = window.URL.createObjectURL(newFile);

        if (cropper) {
          cropper.destroy();
        }

        cropper = new Cropper(accountNewLogoImg, {
          aspectRatio: 16 / 16,
          crop(event) {
            logoCropX.value = event.detail.x;
            logoCropY.value = event.detail.y;
            logoCropW.value = event.detail.width;
            logoCropH.value = event.detail.height;
          },
        });
      }
    });
  }
}
